.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
label {font-weight: 600;}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/**Custom CSS Start**/

.ant-layout-footer {padding: 15px 50px;}
.ant-input-outlined:hover {border-color: #08184f;}
.ant-layout .ant-layout-sider {background: linear-gradient(180deg, #FFF 5.62%, #08184F 15.79%); box-shadow: 0px 62.486px 35.706px -51.328px rgba(17, 10, 28, 0.87);}
.ant-menu-dark {background: transparent;}
.ant-menu-dark .ant-menu-item-selected {background:#ffffff31; border-radius: 0; margin:0; width: 100%;}
.ant-menu-item {margin: 0!important; border-radius: 0; margin-bottom: 10px!important;}
:where(.css-dev-only-do-not-override-zl9ks2).ant-layout .ant-layout-sider-trigger  {    background: #ffffff31;}
.badge-btn-dark {border:2px solid darkblue; background: transparent; color:darkblue; }
.ant-form-item-explain-error {
  max-width: 200px;
  max-height: 200px;
  white-space: wrap;
  text-overflow: ellipsis;
  line-height: 15px;
  padding: 5px 0;
}


/* styles.css */
@keyframes spin {
  0% { transform: rotate(0deg); }
  10% { transform: rotate(36deg); }
  20% { transform: rotate(72deg); }
  30% { transform: rotate(108deg); }
  40% { transform: rotate(144deg); }
  50% { transform: rotate(180deg); }
  60% { transform: rotate(216deg); }
  70% { transform: rotate(252deg); }
  80% { transform: rotate(288deg); }
  90% { transform: rotate(324deg); }
  100% { transform: rotate(360deg); }
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(8, 3, 33, 0.29);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  width: 125px;
  height: 70px;
  background-image: url('../src/assets/img/spinner.png');
  background-size: contain;
  background-repeat: no-repeat;
  animation: spin 1s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
    opacity: 0.5; /* Yarı şeffaf olması için */
  }
  50% {
    transform: rotate(180deg); /* Yarım dönüş */
    opacity: 1; /* Tamamen görünür */
  }
  100% {
    transform: rotate(360deg);
    opacity: 0.5; /* Yarı şeffaf olması için */
  }
}


.dark-gray-back {background-color: #3e424d;}
.w-100 {width: 100%;}
.h-100 {height: 100%;}
.p-10 {padding: 10px;}
.p-15 {padding: 15px;}
.mt-5 {margin-top: 5px;}
.mb-5 {margin-bottom: 5px;}
.ms-5 {margin-left: 5px;}
.me-5 {margin-right: 5px;}
.mt-10 {margin-top: 5px;}
.mb-10 {margin-bottom: 5px;}
.ms-10 {margin-left: 5px;}
.me-10 {margin-right: 5px;}
.m-0 {margin: 0 !important;}
.p-0 {margin: 0 !important;}
.text-center {text-align: center;}
.rounded-10 {border-radius: 10px;}
.rounded-50 {border-radius: 50%;}
.dark-gray-back {color: white;}
.light-gray-back {background-color: #eaeff7; padding: 5px;}
.prev-iframe {border-radius: 0px 0px 10px 10px;
}

.flex-empty { 
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;}


.ant-empty-description span {color:white;}
.ant-form-vertical .ant-form-item-label {padding: 0;}
:where(.css-dev-only-do-not-override-zl9ks2).ant-form-item {margin-bottom: 16px;}
.page-header {  position: absolute; top: 12px;}
.ant-menu .ant-menu-item-group-title {color: rgb(255 255 255 / 35%);}

/**Custom CSS End**/



/*Grapes.js*/

::-webkit-scrollbar {
  width: 5px; 
  height: 5px; 
}

::-webkit-scrollbar-track {
  background-color: 00FFFFFF;
}

::-webkit-scrollbar-thumb {
  background-color: #88888850; /* color of the thumb */
  border-radius: 5px; /* rounded corners for the thumb */
}

#left::-webkit-scrollbar,
#right::-webkit-scrollbar {
  width: 8px;
}

#left::-webkit-scrollbar-thumb,
#right::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.034);
}

#left::-webkit-scrollbar-track,
#right::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

.gjs-pn-panel {
  position: relative;
}

.gjs-pn-panel.gjs-pn-views,
.gjs-pn-panel.gjs-pn-views-container {
  width: 100%;
}

.gjs-pn-panel.gjs-pn-options {
  right: 0;
}

.gjs-pn-panels {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.gjs-title {  background:#374471!important;
    color: white;
    font-size: 15px;
    padding: 0 12px!important;
}


.column {
  min-height: 75px;
  flex-grow: 1;
  flex-basis: 100%;
  overflow: auto;
}

.editor-clm {
  display: flex;
  flex-direction: column;
}

.gjs-cv-canvas {
  width: 100%;
  height: 100%;
  top: 0;
}

.gjs-blocks-cs {
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-top: none;
}

/* Theming */


.gjs-three-bg {
  background-color: #1e8fe1;
  color: white;
}

.gjs-four-color,
.gjs-four-color-h:hover {
  color: #1e8fe1;
}

.editable-label:hover {font-style: italic; color:red;  text-decoration: underline; border:1px dashed;}
.gjs-block {width: 25%; min-height: auto; margin: 0;}
.gjs-block .gjs-block-label {display: none;}
.gjs-block__media {margin: 0;}
.custom-glassy-sidebar {
  box-shadow: 0 4px 30px rgb(0 0 0 / 35%);
  background: rgb(7 7 55);
}
.gjs-block-category.gjs-open {max-height: 300px;
  overflow-x: hidden; overflow-y: auto;}
.hover\:bg-black:hover {background-color: #ffffff26!important;}

.wo.gjs-block {
  display: block;
  overflow-y: auto;
  overflow-x: hidden; width: 100%; text-align: left; min-height: auto; margin: 0;
}
.wo.gjs-block .gjs-block-label {display: block!important; font-size: 13px; color: #e3e3e3; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;}



